<route>
{
  "meta": {
    "permission": [
      "sales.view_typedate"
    ]
  }
}
</route>

<template>
  <div class="px-3">
    <dialtype v-model="dialog" :to-edit="toEdit" @done="reload = true" />
    <i-table
      api="dashboard.typedate"
      app="sales.typedate"
      :headers="headers"
      :title="$tc('typedate', 2)"
      :reload.sync="reload"
      :opt="{ company: this.company.pk }"
      @click:create="open()"
      @click:edit="open($event)"
    >
    </i-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dialtype from '../../components/typeDate/modal.vue'
export default {
  props: {
    template_id: Number
  },
  components: {
    dialtype
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$tc('template', 1),
          value: 'templateName'
        },
        {
          width: 140,
          align: 'center',
          text:
            this.typeDateBtn.delete || this.typeDateBtn.edit
              ? this.$t('action')
              : '',
          value:
            this.typeDateBtn.delete || this.typeDateBtn.edit ? 'actions' : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      company: 'company/getCompanyData',
      isAdmin: 'session/isAdmin'
    }),
    typeDateBtn() {
      return {
        delete: this.getPermissions([`sales.delete_typedate`]),
        edit: this.getPermissions([`sales.change_typedate`])
      }
    }
  },
  data() {
    return {
      reload: false,
      dialog: false,
      toEdit: null
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    }
  }
}
</script>
